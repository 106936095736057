@import './variables.scss';

.App{
    margin: 0;
    height: 100vh;
    width: 100vw;
    font-family: 'News Cycle';
    overflow-x: hidden;
}

.App{
    overflow-y: scroll;

    >h1{
        font-size: 5.5em;
        font-weight: 600;    
    }
}


footer{
    position: fixed;
    bottom: 2%;
    left: 0;
    width: 150px;

    a div:hover{
        background-color: $button-sec-bg-color;
    }
    #email{
        margin-bottom: 4px !important;    
    }
    #email::before, #git::before{
        content: ' ';
        position: absolute;
        width: 20px;
        height: 20px;
        margin-top: 2px;
        left: 3px;
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
    }
    #email::before{
        background-image: url('../assets/mail.png');
    }
    #git::before{
        background-image: url('../assets/git.png');
    }
}